<template>
  <v-app id="main-app">
<span class="d-flex justify-end align-center ma-6">
      <span style="width: 8%">
        <v-select
            class=""
            v-model="languageSelect"
            :items="langs"
            item-text="name"
            item-value="id"
            menu-props="auto"
        >
          <template #prepend>
            <div class="mt-n1">
            <country-flag :country=" languageSelect === 'en' ? 'usa' : 'esp' "/>
            </div>
          </template>
        </v-select>
      </span>
    </span>
    <v-container class="fill-height d-flex justify-center flex-column" fluid>
     <span class="d-flex justify-center align-center my-0">
          <img
              :src="logo"
          />
          <h2 style="margin-left: 10px; font-weight: 400;">{{$t('product-name')}}</h2>
        </span>
      <v-card color="white" class="mx-auto rounded mt-6" width="400px">
        <v-alert
            v-if="alertSuccess"
            dense
            text
            type="success"
        >
          {{$t('successfullyDeletedAccount')}}
        </v-alert>
        <v-alert
            v-if="alertError"
            dense
            text
            type="error"
        >
          {{$t('failedDeletingAccount')}}
        </v-alert>
        <span class="my-3">
			</span>
        <v-card-title class="justify-center pt-5"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
        <v-card-subtitle>
          <v-btn
              text
              @click="redirect"
              class="title-forgot-password mb-5"
          >
            <v-icon class="primary--text mr-3">mdi-arrow-left-bottom</v-icon>
            <span style="font-size: 14px" class="primary--text">{{ $t('Back to Sign in Page') }}</span>
          </v-btn>
          <v-row
              class="font-bold text-center my-0 d-flex justify-center align-center main_black--text title-login"
              style="font-size: 20px !important;"
          >
            {{ $t('welcomeToWellnessmetric') }}  {{$t('product-name')}}
          </v-row>
        </v-card-subtitle>
        <v-card-subtitle class="mt-n5">
        <span
            class="text-center main_black--text"
            style="font-size: 16px !important; margin: 0 8px; padding: 4px; opacity: 0.7"
        >
            {{ $t('moto') }}
          </span>
        </v-card-subtitle>
        <v-card-subtitle class="text-center primary--text title-login py-0" style="font-size: 16px; !important">{{$t('account-deletion-request-info')}}</v-card-subtitle>
        <alert-list-auth />
        <v-card-text class="text-center">
          <v-form ref="form1">
            <v-text-field
                v-model="email"
                ref="email"
                v-bind:label="$t('email')"
                name="email"
                type="email"
                dense
                outlined
                :rules="[rules.emailRequired, rules.email]"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-5 py-0">
          <v-spacer />
          <v-btn
              color="primary2 login-button"
              style="border-radius: 10px; font-size: 12px;"
              :loading="loading"
              :disabled="loading"
              @click="sentAccountDeletionRequest"
          >
            <span style="text-transform: none;">{{$t('deleteAccount')}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="mt-5">
        <v-row dense>
          <v-col class="text-center  main_black--text ma-0 pa-0" cols="12">
              <span style="font-size: 14px; opacity: 0.7">
                {{ $t('signing-in-to-this-webapp') }}
              </span>
            <span
                style="font-size: 14px; cursor: pointer;"
                class="primary2--text"
                @click="redirectToWebsiteTerms"
            >
                {{ $t('website-terms-of-use') }}
              </span>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 pa-0 ">
          <v-col class="text-center  main_black--text mt-0 pt-0" cols="12">
              <span style="font-size: 14px; opacity: 0.7">
                {{ $t('acknowledge') }}
              </span>
            <span
                class="primary2--text"
                style="font-size: 14px; cursor: pointer;"
                @click="redirectToPrivacyTerms"
            >
                {{ $t('privacy-policy') }}
              </span>
          </v-col>
        </v-row>
      </div>

      <v-overlay v-if="loadingApi">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-overlay>
    </v-container>

  </v-app>
</template>

<script>
import AlertListAuth from '../Client/components/AlertListAuth.vue';
import logoBridge from '../../../public/logo_bridge.png';
import { mapGetters, mapState } from 'vuex';
// import CountryFlag from 'vue-country-flag';

import { logoLogin } from '@/assets';

export default {
  components: {
    AlertListAuth,
    // CountryFlag,
  },
  data () {
    return {
      languageSelect: 'en',
      langs: [
        { id: 'en', name: 'EN' },
        { id: 'es', name: 'ES' },
        // { id: 'mk', name: 'English' },
      ],
      loading: false,
      loadingApi: false,
      logo: logoLogin,
      showPassword: false,
      logo_bridge: logoBridge,
      rules: {
        emailRequired: value => !!value || this.$t('emailRequiredMaterial'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('enterValidEmail');
        },
        // passwordRequired: value => !!value || this.$t('passwordEnterPassword'),
        passwordRequired: value => !!value || this.$t('passwordEnterPassword'),
      },
      email: '',
      alertError: false,
      alertSuccess: false,
    };
  },
  computed: {
    ...mapGetters({ delay: 'authentication/getDelay' }),
    ...mapState({
      userDetails: (state) => state.authentication.userData,
    }),
  },
  watch: {
    languageSelect (val) {
      this.$cookies.set('language', val, '6m');
      this.$i18n.locale = this.$cookies.get('language');
    },
  },
  mounted () {
    if (this.$store.getters['authentication/hasStoredSession']) {
      this.$store.dispatch('authentication/doLogout');
      this.$store.commit('alerts/clear');
      localStorage.clear();
    }
    this.$i18n.locale = 'en';
  },
  methods: {
    redirect () {
      this.$router.push('auth/login');
    },
    language (val) {
      this.$cookies.set('language', val, '6m');
      this.$i18n.locale = this.$cookies.get('language');
    },
    redirectToWebsiteTerms () {
      window.open(
          'https://www.wellnessmetric.net/wellnessmetric_website_terms_of_use.html',
          '_blank',
      );
    },
    redirectToPrivacyTerms () {
      window.open(
          'https://www.wellnessmetric.net/wellnessmetric_website_privacy_policy.html',
          '_blank',
      );
    },
    async sentAccountDeletionRequest () {
      this.loadingApi = true;

      const accountDeletionRequest = {
        email: this.email,
      };

      await this.$store.dispatch('users/sendProfileRemovalEmail', accountDeletionRequest)
          .then(async (res) => {
            try {
              if (res.resFlag === true) {
                this.alertError = false;
                this.alertSuccess = true;
              } else {
                this.alertSuccess = false;
                this.alertError = true;
              }
            } finally {
              this.loadingApi = false; // Set loading to false after API call is finished
              setTimeout(() => {
                this.alertError = false;
                this.alertSuccess = false;
              }, 5000);
            }
          });
    }
,
  },
};
</script>
<style scoped>
#main-app {
  background: #F9F8FB;
}
</style>
