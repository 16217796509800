<template>
	<div class="alert-container">
		<v-alert
			v-for="(alert, index) in alerts"
			:key="index"
			:color="alert.color"
			:dismissible="!alert.sticky"
			:icon="alert.icon"
			:type="alert.type"
			:dense="alert.important"
			:prominent="alert.important"
		>
			{{ alert.message }}
			<template #close>
				<v-btn
					class="v-alert__dismissible"
					:color="alert.color"
					:icon="true"
					:small="true"
					:aria-label="$t('close')"
					@click="() => remove(alert)">
					<v-icon :color="alert.color">mdi-close-circle</v-icon>
				</v-btn>
			</template>
		</v-alert>
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
	computed: {
		...mapState({
			alerts: s => s.alerts.authAlerts,
		}),
	},
	methods: {
		...mapMutations({
			remove: 'alerts/remove',
		}),
	},
};
</script>
